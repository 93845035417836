import { sanityConfig } from "@libry-content/common";
import { createClient } from "next-sanity";

export let sanityAuthToken: string | undefined;

// In our preview mode setup we use the token explicitly, therefore we get it from localStorage here
// (using a similar method to https://github.com/sanity-io/sanity/pull/3155). In order to make sure
// the token is always defined for logged in users, we set `loginMethod: "token"` in the Sanity config.
if (typeof window !== "undefined" && !!localStorage) {
  const localStorageToken = localStorage.getItem(`__studio_auth_token_${sanityConfig.projectId}`);
  try {
    sanityAuthToken = localStorageToken ? JSON.parse(localStorageToken)?.token : undefined;
  } catch (err) {
    console.error("Could not parse Sanity auth token from localStorage:", err);
  }
}

// Don't include token here, or client side fetches will include drafts
export const sanityClient = createClient({
  ...sanityConfig,
  useCdn: process.env.NODE_ENV === "production",
});
