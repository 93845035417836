import { Container, ContainerProps, ThemeTypings } from "@biblioteksentralen/js-utils";

type Props = {
  /** Remove horizontal padding bellow a certain threshold if you want a full-screen effect for your component on small screen sizes */
  withoutPaddingXBellow?: ThemeTypings["breakpoints"];
} & ContainerProps;

export const DefaultContainer = ({ withoutPaddingXBellow: withoutPaddingBellow, ...chakraProps }: Props) => (
  <Container
    maxW="container.lg"
    alignSelf="center"
    paddingX={{ base: 0, [withoutPaddingBellow ?? "base"]: "0.75rem" }}
    {...chakraProps}
  />
);

export const HeaderFooterContainer = (props: ContainerProps) => (
  <Container
    // media-queries her for at innhold i header og footer skal aligne med annet innhold på medium store skjermer. Da er header og footer nesten like brede som innholdet, og det ser ut som en styling-feil. Bedre å sørge for at de er akkurat like brede i de tilfellene
    maxW={{ base: "container.lg", xl: "container.xl" }}
    paddingX={{ base: ".75rem", xl: "1.25rem" }}
    {...props}
  />
);
