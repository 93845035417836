import { useRouter } from "next/router";
import { createContext, lazy, ReactNode, Suspense, useContext, useEffect, useState } from "react";
import { usePrevious } from "react-use";
import PreviewBanner from "./PreviewBanner";
import { sanityAuthToken } from "../../utils/sanity/client";

const SanityLiveQueryProvider = lazy(() => import("./SanityLiveQueryProvider"));

type State = {
  /**
   * undefined means it's pending, waiting for queryparams to be available (tracked by isPreviewModeEstablished)
   *
   * Some functionality, etc metrics, should not be loaded before it's clear wether preview is on or off.
   * We don't want to log metrics when the page is displayed in previewmode i sanity studio.
   * */
  isOn?: boolean;
  isPreviewModeEstablished: boolean;
  toggle: () => void;
  setPreviewMode: (isOn: boolean) => void;
};

const initState: State = {
  isOn: undefined,
  isPreviewModeEstablished: false,
  toggle: () => null,
  setPreviewMode: () => null,
};

const Context = createContext<State>(initState);

export const usePreviewContext = () => useContext(Context);
interface Props {
  children: ReactNode;
}

export const PreviewContextProvider = (props: Props) => {
  const router = useRouter();
  const [previewMode, setPreviewMode] = useState(initState.isOn);

  /**
   * Whether we know if preview mode is enabled – the query parameter not being immediately available on the client
   */
  const isPreviewModeEstablished = typeof previewMode !== "undefined";

  const prevRouter = usePrevious(router);
  useEffect(() => {
    // I det router.isReady endres fra false til true blir query-params også tilgjengelig fra router og vi kan sette previewMode false/true basert på om preview er satt i url vha "?preview=true"
    if (!prevRouter?.isReady && router.isReady) {
      const previewInQueryParams = router.query.preview === "true";
      setPreviewMode(previewInQueryParams);
    }
  }, [prevRouter, router]);

  if (previewMode && !sanityAuthToken) {
    console.error("Missing Sanity auth token in preview");
  }

  const contextProviderNode = (
    <Context.Provider
      value={{
        isOn: previewMode,
        isPreviewModeEstablished,
        toggle: () => setPreviewMode((s) => !s),
        setPreviewMode: (isOn) => setPreviewMode(isOn),
      }}
    >
      <PreviewBanner />
      {props.children}
    </Context.Provider>
  );

  if (previewMode && sanityAuthToken) {
    return (
      <Suspense fallback={contextProviderNode}>
        <SanityLiveQueryProvider token={sanityAuthToken}>{contextProviderNode}</SanityLiveQueryProvider>
      </Suspense>
    );
  }

  return contextProviderNode;
};
